import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';
import { createBoilerplateTheme } from "@gisce/oficina-virtual-components";
// import Roboto from "./fonts/roboto.scss";
// import { lighten } from '@mui/material';

import logo from '/images/logo.png'
import logoBar from '/images/logoBar.png'

const customConstants: ThemeOptions = {
  palette: {
    primary: {
      main: "#054c32",
    },
    secondary: {
      main: "#fcbd1b",
    }
  },
  custom: {
    constants: {
      logoImage: logo,
      logoBarImage: logoBar,
      borderRadius: 16,
      appBarHeightLaptop: 88,
      appBarBackgroundColor: "#eaeeeb",
      
    }
  }
};

let customTheme = createBoilerplateTheme(customConstants) as Theme;

customTheme = createTheme(customTheme, {
  palette: {
    secondary: {
      contrastText: customTheme.palette.primary.main, 
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: customTheme.palette.primary
          }
        },
        {
          props: { variant: 'contained' },
          style: {
            color: customTheme.palette.primary,
          }
        },
      ],
      defaultProps: {
        color: 'secondary'
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: customTheme.palette.primary.main
        }
      }
    },
  },
  custom: {
    constants: {
      appBarTextColor: customTheme.palette.primary.main,
      defaultButtonColor: "secondary"
    },
    components: {
      reactFormigaTable: {
        style: {
          containerStyle: {
            borderRadius: `${customTheme?.custom?.constants?.borderRadius}px`,
          },
        }
      },
      publicityBanner: {
        stylingProps: {
          titleColor: customTheme.palette.secondary.main
        },
      },
      languageSelect: {
        buttonStyle: {
          color: "primary"
        }
      }
    }
  }
});

export default customTheme;

